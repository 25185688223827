import type { ZodArray, ZodDate, ZodEffects, ZodError, ZodNumber, ZodObject, ZodRawShape, ZodString } from 'zod'
import * as zod from 'zod'
import { intersects, isArray, isEmpty } from 'radash'
import { createConditionFromValues } from '../properties'
import type { PropertyFieldProps } from '~~/src/types/Properties'

export const createValidationSchema = (fields: PropertyFieldProps[], initialValues?: Record<string, unknown>): ZodObject<ZodRawShape> => {
    const validationObject: Record<string, ZodString | ZodNumber | ZodEffects<ZodDate, Date, Date> | ZodArray<ZodString, 'many'>> = {}

    let refinements: Record<string, [string, '===' | '!==', string]> | null = null

    fields.forEach((field) => {
        if (field.fieldType === 'label') { return }
        let currentValidationValue
        if (field.fieldType === 'multi-select') {
            currentValidationValue = zod.array(zod.string())
        } else
            if (['integer', 'decimal', 'currency'].includes(field.fieldType)) { currentValidationValue = zod.number() } else {
                currentValidationValue = zod.string({
                    required_error: 'Required'
                })
            }
        if (field.fieldType === 'integer') { currentValidationValue = (currentValidationValue as ZodNumber).int() }
        if (field.fieldType === 'email') { currentValidationValue = (currentValidationValue as ZodString).email('Must be valid email') }
        if (!field.required) { currentValidationValue = zod.union([currentValidationValue, zod.string().length(0), zod.null(), zod.undefined()]).optional() }

        if (field.required) {
            if (field.fieldType === 'phone') { currentValidationValue = currentValidationValue.length(10, { message: 'Requires exactly 10 digits' }) } else {
                currentValidationValue = currentValidationValue.min(['integer', 'decimal', 'currency'].includes(field.fieldType) ? Number(field.minimalNumber) : 1, { message: field.minimalNumber ? `Requires a minimal number of ${field.minimalNumber}` : 'Required' })
            }
            currentValidationValue = currentValidationValue.nullable().transform((value, ctx): number => {
                if (value === null) {
                    ctx.addIssue({
                        code: 'custom',
                        message: 'Required'
                    })
                }
                return value ?? NaN
            })
        }

        if (field.requiredIf) {
            const [fieldKey, operator, fieldValue]: [string, '===' | '!==', string] = JSON.parse(field.requiredIf)
            refinements = { ...refinements, [field.fieldKey]: [fieldKey, operator, fieldValue] }
        }
        validationObject[field.fieldKey as string] = currentValidationValue
    })
    let zodObject = zod.object(validationObject)

    if (refinements) {
        const fieldsWithRefinements = Object.keys(refinements)
        const paths = []
        zodObject = zodObject.superRefine((keys, ctx) => {
            fieldsWithRefinements.forEach((refinementFieldKey) => {
                const [fieldKey, operator, fieldValue] = refinements[refinementFieldKey]

                let isConditionTrue
                const leftValue = initialValues ? initialValues[fieldKey] : keys[fieldKey]

                if (isArray(leftValue) && isArray(fieldValue)) {
                    isConditionTrue = intersects(leftValue, fieldValue)
                } else
                    if (isArray(leftValue)) {
                        isConditionTrue = leftValue.includes(fieldValue)
                    } else
                        if (isArray(fieldValue)) {
                            isConditionTrue = fieldValue.includes(leftValue)
                        } else {
                            const conditionFromValuesHash = createConditionFromValues(leftValue, fieldValue)
                            isConditionTrue = !leftValue ? false : conditionFromValuesHash[operator]
                        }

                if (isConditionTrue && (![0, '0'].includes(keys[refinementFieldKey]) && isEmpty(keys[refinementFieldKey]))) {
                    ctx.addIssue({
                        code: zod.ZodIssueCode.custom,
                        path: [refinementFieldKey],
                        message: 'Required'
                    })
                }
            })
            return !paths.length
        })
    }
    return zodObject
}

export const validateSchema = (validationSchema: Record<string, any>, values: Record<string, unknown>) => {
    const test = validationSchema.safeParse(values)

    const errors: Record<ZodError['issues'][number]['path'][number], string> = {}
    if (test.error as ZodError) {
        (test.error.issues as ZodError['issues']).forEach((issue) => {
            errors[issue.path[0]] = issue.message
        })
    }

    return errors
}
