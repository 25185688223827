export const formatValue = (value: string | string[] | number, fieldType: string) => {
    if (fieldType === 'currency') { return Intl.NumberFormat('en-US').format(Number(value)) }
    if (fieldType === 'date') { return new Date(value as string).toLocaleDateString('en-US') }
    if (fieldType === 'phone') {
        const number = String(value).replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/)
        if (!number) { return value }
        return '(' + number[1] + ') ' + number[2] + '-' + number[3]
    }
    if (Array.isArray(value)) { return value.join(', ') }

    return value
}
